import React from 'react';
import type { UseFormReturn } from 'react-hook-form';
import type { AnySchema } from 'yup';
import type { Values as BusinessDetailsFields } from '@components/page-steps/BusinessDetailsPage';
import type { Values as ProcessingPageFields } from '@components/page-steps/ProcessingPage';
import type { Values as ApplicationProcessingFields } from '@components/page-steps/ApplicationProcessPage';
import type { Values as ManagingFields } from '@components/page-steps/ManagingDetailsPage';
import type { Values as BankPageFields } from '@components/page-steps/BankDetailsPage';
import type { Values as EquipmentFields } from '@components/page-steps/EquipmentPage';
import type { Values as SignPageFields } from '@components/page-steps/SignPage';
import type { SurchargeAddendumFields } from '@components/page-steps/sign-page-components/SurchargeAddendum';
import type { NextNavigateProps } from '@hooks/useNavigationContext';
import type { FileType, Owner } from '@hooks/types';
import { mapFormValuesToAPI } from '@app/opportunity/[id]/utils';

export type WizardContextType = {
    steps: WizardStep[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: UseFormReturn<AllFields>;
    isSigner?: boolean;
    isSubmitted?: boolean;
    isOpportunity?: boolean;
    showBackToReview?: boolean;
    isApplication?: boolean;
    hasEIServerOrCloud: boolean;
    hasEmergepay: boolean;
    isSalesUser?: boolean;
    loading: boolean;
    completedSteps: number;
    onComplete: (values: any) => Promise<void>;
    onFileRemoval: (fileId: string) => Promise<void>;
    onFileUpload: (file: File, name?: string, type?: FileType) => Promise<{ id: string, url: string }|undefined>;
    onOwnerAdd: (owner?: Omit<Owner, 'id'>) => Promise<{ id: string }>;
    onOwnerRemoval: (ownerId: string) => Promise<void>;
    id: string;
    getCurrentStep: () => WizardStep | undefined;
    setLoading: (loading: boolean) => void;
    setShowBackToReview: (val: boolean) => void;
    pdfComponent: (values: AllFields) => JSX.Element;
};

export type WizardProps = {
    children: React.ReactNode;
    hasEIServerOrCloud: boolean;
    hasEmergepay: boolean;
    isOpportunity?: boolean;
    isApplication?: boolean;
    isSalesUser?: boolean;
    isSigner?: boolean;
    isSubmitted?: boolean;
    onComplete?: (values: AllFields) => Promise<void>;
    id: string;
    onFileRemoval?: (fileId: string) => Promise<void>;
    onFileUpload?: (file: File, name?: string, type?: FileType) => Promise<{ id: string, url: string }|undefined>;
    onOwnerAdd?: () => Promise<{ id: string }>;
    onOwnerRemoval?: (ownerId: string) => Promise<void>;
    pdfComponent?: (values: AllFields) => JSX.Element;
    showFooter?: boolean;
    steps: {
        name: string,
        title: string,
        fields: readonly string[],
        schema: AnySchema,
        children: React.ReactNode,
    }[],
    values?: { [key: string]: any } | null;
};

export type WizardStep = {
    fields?: string[],
    onNext?: () => Promise<NextNavigateProps> | NextNavigateProps;
    schema?: AnySchema;
    name: string;
    footer?: {
        enabled?: boolean;
        nextButtonDisabled?: boolean;
        buttonSteps?: (string | JSX.Element)[];
        className?: string;
        nextBtnLabel?: string;
    };
    children?: React.ReactNode;
    title: string;
};

export enum BusinessFields {
    DBANAME = 'dbaName',
    LEGALNAME = 'legalName',
    B_ADD1 = 'businessAddr1',
    B_ADD2 = 'businessAddr2',
    B_CITY = 'businessCity',
    B_STATE = 'businessState',
    B_ZIPCODE = 'businessZipCode',
    L_ADD1 = 'legalAddr1',
    L_ADD2 = 'legalAddr2',
    L_CITY = 'legalCity',
    L_STATE = 'legalState',
    L_ZIPCODE = 'legalZipCode',
    IS_LEGAL_SAME_AS_PYSICAL = 'legalIsPhysicalAddress',
    B_TAX_METHOD = 'businessTaxPayMethod',
    B_EMAIL = 'businessEmail',
    B_PHONE = 'businessPhone',
    ENTITY_TYPE = 'entityType',
    TIN = 'tin',
    DOE = 'dateOfEstb',
    DOT = 'targetDate',
    NO_OF_LOCATIONS = 'locationCount',
    B_WEBSITE = 'businessUrl',
}

export enum ProcessingFields {
    MARKETING_WEBSITE = 'marketingWebsite',
    MARKETING_FILE = 'marketingFile',
    TXN_COMPLETE_PERIOD = 'transactionCompletePeriod',
    TXN_COMPLETE_PERIOD_OTHER = 'transactionCompletePeriodOther',
    DAYS_TO_RETURN_CANCEL = 'daysToReturnOrCancel',
    IS_GS_DELIVERY_AFTER_TXN_DAY = 'isGSDeliveryAfterTxnDay',
    DELIVERY_IMMEDIATE = 'delivery_immediate_Percentage',
    DELIVERY_1_3_DAYS = 'delivery_1_3_DaysPercentage',
    DELIVERY_4_7_DAYS = 'delivery_4_7_DaysPercentage',
    DELIVERY_8_14_DAYS = 'delivery_8_14_DaysPercentage',
    DELIVERY_15_30_DAYS = 'delivery_15_30_DaysPercentage',
    DELIVERY_OVER_30_DAYS = 'delivery_Over_30_DaysPercentage',
    RECURRING_BILLING = 'recurringMonthly',
    BILLING_PERIOD = 'billingPeriod',
    DB_COLLECTING_CC_NUMBERS = 'entireCreditCardCollect',
    PCI_COMPLIANT = 'PCICompliant',
    DOES_CUS_SIGN_CONTRACT = 'doesCustomerSignContract',
    CONTRACT_FILE = 'contractFile',
    HOW_CUSTOMER_ORDER = 'howCustomerOrder',
    VENDOR_PUR_ADDRESS = 'vendorPurchaseAddress',
    IS_CNP_REQUIRED = 'cnpAgreementRequired',
    SERVICE_FOR_CC_HOLDERS = 'serviceForCardHolder',
    AVG_TRANSACTION_SIZE = 'avgTransactionSize',
    HIGH_TICKET_VOLUME = 'high_ticket_volume',
    IS_ACCEPTING_CC_CARDS = 'isCurrentlyAcceptingCreditCards',
    EXPECTED_MONTHLY_CC_VOLUME = 'expectedMonthlyCCVolume',
    MOTO_PERCENT = 'motoPercentage',
    INTERNET_PERCENT = 'internetPercentage',
    KEYED_PERCENT = 'keyedPercentage',
    SWIPED_PERCENT = 'swipedPercentage',
    IS_SEASONAL = 'isSeasonal',
    SEASONAL_FROM = 'seasonalFrom',
    SEASONAL_TO = 'seasonalTo',
}

export enum BankFields {
    P_BANK_ROUTING_NUMBER = 'primaryBankRoutingNumber',
    P_BANK_ACCOUNT_NUMBER = 'primaryBankAccountNumber',
    P_BANK_ACCOUNT_FULL_NAME = 'primaryBankAccountHolderFullName',
    P_ACC_BANK_NAME = 'primaryAccountBankName',
    P_STATEMENT_1 = 'processingStatement1',
    P_STATEMENT_2 = 'processingStatement2',
    P_STATEMENT_3 = 'processingStatement3',
    P_BANK_ACC_FILE = 'primaryAccountFile',
    EXTRA_FILES = 'extraFiles',
    WAIVE_PERSONAL_GUARANTEE = 'waivePersonalGuarantee',
    GUARANTEE_STATEMENT1 = 'guaranteeStatement1',
    GUARANTEE_STATEMENT2 = 'guaranteeStatement2',
    GUARANTEE_STATEMENT3 = 'guaranteeStatement3',
    GUARANTEE_B_SHEET = 'guaranteeBalanceSheet',
    GUARANTEE_PL_STATEMENT = 'guaranteePLStatement',
    PROCESSING_STATEMENT1 = 'processingStatement1',
    PROCESSING_STATEMENT2 = 'processingStatement2',
    PROCESSING_STATEMENT3 = 'processingStatement3',
    MASKED_P_BANK_ACCOUNT_NUMBER = 'maskedPrimaryBankAccountNumber',
    USE_ALTERNATIVE_ACCOUNT = 'useAlternativeAccount',
    ALT_BANK_ROUTING_NUMBER = 'altBankRoutingNumber',
    ALT_BANK_ACCOUNT_NUMBER = 'altBankAccountNumber',
    ALT_BANK_ACCOUNT_FULL_NAME = 'altBankAccountHolderFullName',
    ALT_ACC_BANK_NAME = 'altAccountBankName',
    ALT_BANK_ACC_FILE = 'altAccountFile',
    MASKED_ALT_BANK_ACCOUNT_NUMBER = 'maskedAltBankAccountNumber',
}

export enum SignFields {
    TIMESTAMP = 'timestamp',
    IP_ADDRESS = 'ipAddress',
    SIGNATURE = 'keyedInSignature',
    SIGNER_NAME = 'signerName',
    FORMATTED_TIMESTAMP = 'formattedTimestamp'
}

export enum EquipmentSetUpFields {
    SHIPPING_ADD1 = 'shippingAddr1',
    SHIPPING_ADD2 = 'shippingAddr2',
    SHIPPING_CITY = 'shippingCity',
    SHIPPING_STATE = 'shippingState',
    SHIPPING_ZIPCODE = 'shippingZipCode',
    IS_SHIPPING_SAME_AS_PHYSICAL = 'shippingIsPhysicalAddress',
    SHIPPING_PHONE = 'shippingPhone',
    INSTALL_CONTACT_FIRST_NAME = 'installContactFirstName',
    INSTALL_CONTACT_LAST_NAME = 'installContactLastName',
    INSTALL_CONTACT_PHONE = 'installContactPhone',
    INSTALL_CONTACT_EMAIL = 'installContactEmail',
    UTILIZE_TIPS = 'utilizeTips',
    UTILIZE_CARD_STORAGE = 'utilizeCardStorage',
    WINDOWS_TEN_OR_NEWER = 'windowsTenOrNewer',
    ETHERNET_AVAILABLE = 'ethernetAvailable',
    FEET_CC_MACHINE_TO_ROUTER = 'feetfromCreditCardMachineToRouter',
    FIVE_PORT_SWITCH_PURCHASE = 'fivePortSwitchPurchase',
    POS_ACCESS = 'posAccess',
    HAND_KEY_TRANSACTIONS = 'handKeyTransactions',
    NO_OF_COMPUTERS = 'numberOfComputers',
    IS_ACCEPTING_GIFT_CARDS = 'isAcceptingGiftCards',
    GIFT_CARD_PROVIDER = 'giftCardProvider',
    BALANCE_SHEET_PROVIDED = 'balanceSheetProvided',
    BLANK_GIFT_CARD_PROVIDED = 'blankGiftCardProvided',
    IS_INTERESTED_GIFT_CARDS = 'isInterestedAcceptingGiftCards',
    NO_OF_WORK_STATIONS = 'numberOfWorkStations',
    INSTALL_ADMIN_ACCESS = 'adminAccessDuringInstall',
    REPORTING_TRAINING = 'reportingTraining',
    NO_FOR_REPORTING_TRAINING = 'numberForReportingTraining',
    PROCESSING_DATE = 'processingDate',
}

export enum AddendumFields {
    DEBIT_CARD_BILLING = 'debitCardBilling'
}

export type AllFields =
    BusinessDetailsFields
    & ApplicationProcessingFields
    & ProcessingPageFields
    & ManagingFields
    & BankPageFields
    & EquipmentFields
    & SurchargeAddendumFields
    & SignPageFields
    & {
    opportunityStage: string
};

export type OpportunityApplication = ReturnType<typeof mapFormValuesToAPI>;
